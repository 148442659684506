import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  CallUs,
  BannerLinks,
  TextBox
} from '../components'

import contacts from '../components/CallUs/DefaultContacts'

import BannerImage from '../assets/impressum.jpg'
import JoinTheTeam from '../assets/bannerlinks/jointheteam.jpg'
import TakeOff from '../assets/bannerlinks/takeoff.jpg'

const boxes = [
  {
    text: 'Datenschutz',
    image: JoinTheTeam,
    link: '/datenschutzerklaerung',
    linkText: 'Mehr lesen'
  },
  {
    text: 'Join the team!',
    image: TakeOff,
    link: '/karriere',
    linkText: 'Mehr lesen'
  }
]

const AGB = () => (
  <Layout>
    <Helmet
      title="AGB"
      meta={[
        {
          name: 'description',
          content:
            'Hier können Sie sich die AGBs der holzweg Webagentur herunterladen. Die AGBs sind so vom Fachverband Unternehmensberatung, Buchhaltung und Informationstechnologie für den Verkauf und die Lieferung von Organisations-, Programmierleistungen und Werknutzungsbewilligungen von Softwareprodukten vorgegeben. '
        },
        {
          name: 'keywords',
          content:
            'AGBs holzweg, Webagentur holzweg, AGB, AGBs'
        },
        {
          property: 'og:description',
          content:
          'Hier können Sie sich die AGBs der holzweg Webagentur herunterladen. Die AGBs sind so vom Fachverband Unternehmensberatung, Buchhaltung und Informationstechnologie für den Verkauf und die Lieferung von Organisations-, Programmierleistungen und Werknutzungsbewilligungen von Softwareprodukten vorgegeben. '
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/impressum-57e2b6a898328735d5e7650049000b57.jpg'
        }
      ]}
    />
    <BannerSmall image={BannerImage} title="Better safe than sorry" />
    <TextBox
      title="holzweg AGBs"
      text={
        <span><p>
        Hier können Sie sich unsere <a href="/downloads/IT-AGBs.pdf" target="_blank">AGBs herunterladen</a>. 
        </p>
        </span>
      }
    />
    <CallUs contacts={contacts} title="Kontakte" />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default AGB
